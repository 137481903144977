import { gql } from 'apollo-boost';

// LOCAL
export const IS_LOGGED_IN = gql`
  query isLoggedIn {
    isLoggedIn @client
  }
`;

export const LOCAL_GET_FLASH = gql`
  query localGetFlash {
    localGetFlash @client
  }
`;

export const LOCAL_GET_LOADING = gql`
  query localGetLoading {
    localGetLoading @client
  }
`;

export const IS_SIDEBAR_OPEN = gql`
  query isSidebarOpen {
    isSidebarOpen @client
  }
`;

export const GET_ME = gql`
  query me {
    me {
      id
      createdAt
      updatedAt
      email
      phone
      document
      address {
        id
        street
        streetNumber
        additionalInfos
        city
        state
        zipCode
      }
      farmer {
        id
        firstName
        lastName
        dateOfBirth
      }
      partner {
        id
        name
        administrator
      }
      guarantor {
        id
        administrator
      }
    }
  }
`;

export const ME = gql`
  query me {
    me {
      id
      guarantor {
        id
        administrator
      }
      partner {
        id
        administrator
      }
    }
  }
`;

export const FARMERS = gql`
  query farmers {
    farmers {
      id
      income
      firstName
      guarantor {
        id
        name
      }
      guarantorId
      lastName
      limit
      limitHistory {
        id
        createdAt
        value
      }
      registration
      userId
      guarantorId
      user {
        address {
          city
        }
        approved
        createdAt
        document
        phone
      }
    }
  }
`;

export const FARMER = gql`
  query farmer($id: String!) {
    farmer(id: $id) {
      firstName
      lastName
      gender
      rg
      cardNumber
      dateOfBirth
      guarantorId
      userId
      user {
        document
        phone
        email
        address {
          street
          streetNumber
          additionalInfos
          city
          state
          zipCode
        }
      }
      income
      incomeType
      limit
      guarantor {
        name
      }
      registration
    }
  }
`;

export const PARTNERS = gql`
  query partners {
    partners {
      id
      name
      userId
      companyName
      user {
        approved
        document
        phone
      }
    }
  }
`;

export const PARTNER = gql`
  query partner($id: String!) {
    partner(id: $id) {
      name
      companyName
      administrator
      categoryId
      fee
      category {
        name
      }
      bank {
        id
        name
        agency
        account
      }
      userId
      user {
        phone
        email
        document
        address {
          street
          streetNumber
          additionalInfos
          city
          state
          zipCode
        }
      }
    }
  }
`;

export const GUARANTORS = gql`
  query guarantors {
    guarantors {
      id
      name
      userId
      user {
        approved
        document
        phone
      }
    }
  }
`;

export const GUARANTOR = gql`
  query guarantor($id: String!) {
    guarantor(id: $id) {
      name
      companyName
      administrator
      userId
      user {
        document
        phone
        email
        address {
          street
          streetNumber
          additionalInfos
          city
          state
          zipCode
        }
      }
    }
  }
`;

export const CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query transactions($from: Date, $to: Date) {
    transactions(from: $from, to: $to) {
      id
      createdAt
      amount
      partnerFee
      relatedWith
      creditPercent
      limit
      paymentType
      farmer {
        id
        firstName
        lastName
        dateOfBirth
        rg
        limit
        limitHistory {
          id
          createdAt
          value
        }
        cardNumber
        # usage
        registration
        income
        incomeType
        user {
          approved
          createdAt
          phone
          email
          document

          address {
            city
          }
        }
      }
      partner {
        id
        name
        companyName
        category {
          name
        }
        user {
          document
        }
        bank {
          account
          agency
          name
        }
      }
      guarantor {
        id
        name
        companyName
        user {
          email
          document
          phone
        }
      }
    }
  }
`;

export const ADMIN_DASHBOARD = gql`
  query adminDashboard($from: Date, $to: Date) {
    adminDashboard(from: $from, to: $to) {
      grossIncome
      netProceeds
      transactionsAmount
      refundValue
    }
  }
`;

export const ADMIN_TRANSACTIONS_BY_TIME = gql`
  query adminTransactionByTime($from: Date, $to: Date, $timeType: String, $timezone: String) {
    adminTransactionByTime(from: $from, to: $to, timeType: $timeType, timezone: $timezone) {
      from
      to
      sum
      quantity
    }
  }
`;

export const ADMIN_TRANSACTIONS_BY_CATEGORY = gql`
  query adminTransactionByCategory($from: Date, $to: Date) {
    adminTransactionByCategory(from: $from, to: $to) {
      categoryId
      categoryName
      sum
      quantity
    }
  }
`;

export const ADMIN_TRANSACTIONS_BY_FARMER = gql`
  query adminTransactionByFarmer($from: Date, $to: Date, $timezone: String) {
    adminTransactionByFarmer(from: $from, to: $to, timezone: $timezone) {
      farmerId
      farmerName
      average
      quantity
      sum
    }
  }
`;
