import { DocumentNode, gql } from 'apollo-boost';

// LOCAL
export const LOCAL_LOGOUT: DocumentNode = gql`
  mutation localLogout {
    localLogout @client
  }
`;

export const LOCAL_LOGIN: DocumentNode = gql`
  mutation localLogin($accessToken: String!, $refreshToken: String!) {
    localLogin(accessToken: $accessToken, refreshToken: $refreshToken) @client
  }
`;

export const LOCAL_SET_FLASH: DocumentNode = gql`
  mutation localSetFlash($payload: String!, $type: String!) {
    localSetFlash(payload: $payload, type: $type) @client
  }
`;

export const LOCAL_SET_LOADING: DocumentNode = gql`
  mutation localSetLoading($loading: Boolean!) {
    localSetLoading(loading: $loading) @client
  }
`;

export const SET_SIDEBAR: DocumentNode = gql`
  mutation setSidebar($open: Boolean!) {
    setSidebar(open: $open) @client
  }
`;

export const SIGNUP_FARMER = gql`
  mutation signupFarmer($signupInput: SignupInput!, $farmerInput: FarmerInput!) {
    signupFarmer(signupInput: $signupInput, farmerInput: $farmerInput)
  }
`;

export const SIGNUP_GUARANTOR = gql`
  mutation signupGuarantor($signupInput: SignupInput!, $guarantorInput: GuarantorInput!) {
    signupGuarantor(signupInput: $signupInput, guarantorInput: $guarantorInput)
  }
`;

export const SIGNUP_PARTNER = gql`
  mutation signupPartner($signupInput: SignupInput!, $partnerInput: PartnerInput!) {
    signupPartner(signupInput: $signupInput, partnerInput: $partnerInput)
  }
`;

export const LOGIN = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $forgotPasswordInput)
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshAccessToken($token: String!) {
    refreshAccessToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`;

export const ADMIN_USER_REMOVE = gql`
  mutation adminUserRemove($id: String!) {
    adminUserRemove(id: $id)
  }
`;

export const ADMIN_USER_UPDATE = gql`
  mutation adminUserUpdate(
    $adminUserUpdateInput: AdminUserUpdateInput
    $adminGuarantorUpdateInput: AdminGuarantorUpdateInput
    $adminPartnerUpdateInput: AdminPartnerUpdateInput
    $adminFarmerUpdateInput: AdminFarmerUpdateInput
  ) {
    adminUserUpdate(
      adminUserUpdateInput: $adminUserUpdateInput
      adminGuarantorUpdateInput: $adminGuarantorUpdateInput
      adminPartnerUpdateInput: $adminPartnerUpdateInput
      adminFarmerUpdateInput: $adminFarmerUpdateInput
    )
  }
`;

export const ADMIN_TRANSACTION_CONFIRM = gql`
  mutation adminTransactionConfirm($adminTransactionConfirmInput: AdminTransactionConfirmInput!) {
    adminTransactionConfirm(adminTransactionConfirmInput: $adminTransactionConfirmInput) {
      id
    }
  }
`;

export const ADMIN_REFUND_TRANSACTION = gql`
  mutation adminRefundTransaction($refundTransactionInput: RefundTransactionInput!) {
    adminRefundTransaction(refundTransactionInput: $refundTransactionInput) {
      id
      createdAt
      amount
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($changePasswordInput: ChangePasswordInput!) {
    changePassword(changePasswordInput: $changePasswordInput)
  }
`;
