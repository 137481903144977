export default {
  LOGIN: '/login',
  HOME: '/',

  FARMERS: '/farmers',
  FARMERS_VIEW: '/farmers',
  FARMERS_CREATE: '/farmers/create',
  FARMERS_EDIT: '/farmers/edit',

  PARTNERS: '/partners',
  PARTNERS_VIEW: '/partners',
  PARTNERS_CREATE: '/partners/create',
  PARTNERS_EDIT: '/partners/edit',

  GUARANTORS: '/guarantors',
  GUARANTORS_VIEW: '/guarantors',
  GUARANTORS_CREATE: '/guarantors/create',
  GUARANTORS_EDIT: '/guarantors/edit',
  GUARANTORS_REPORT: '/guarantors/report',

  CONTROL_SHEET_CREATE: '/new-transaction',

  BUSINESS: '/business',
  TRANSACTIONS_REPORT_CREATE: '/business/transactions/create',
  TRANSACTIONS_REPORT: '/business/transactions',
  BUSINESS_DISTRIBUTION_REPORT: '/business/reports/distribution-report',
  BUSINESS_FARMER_REPORT: '/business/farmer-report',
  BUSINESS_FARMER_PROSPECTING_REPORT: '/business/farmer-prospecting-report',

  RESET_PASSWORD: '/settings/change-password',
};
